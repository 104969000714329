import React, { createContext, useContext, useState, useEffect } from "react";

const JobListingsContext = createContext();

export function JobListingsProvider({ children }) {
  const [jobListings, setJobListings] = useState([]);
  const [hasJobs, setHasJobs] = useState(false);

  useEffect(() => {
    const fetchJobListings = async () => {
      try {
        const response = await fetch("/jobListings.json");
        if (!response.ok) {
          throw new Error("Fehler beim Laden der Job-Liste");
        }
        const data = await response.json();
        setJobListings(data);
        setHasJobs(data.length > 0); // Prüfen, ob es Jobs gibt
      } catch (error) {
        console.error("Fehler:", error);
      }
    };

    fetchJobListings();
  }, []);

  return (
    <JobListingsContext.Provider value={{ jobListings, hasJobs }}>
      {children}
    </JobListingsContext.Provider>
  );
}

export function useJobListings() {
  return useContext(JobListingsContext);
}
